import React from 'react';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MDXRenderer } from "gatsby-plugin-mdx";

const useStyles = makeStyles(theme => ({
title: {
  width: "100%",
  textAlign: "center"
},
subContainer: {
  margin: `${theme.spacing(6)}px 0px`,
  "& p" :{
    textAlign: "start !important",
    fontWeight: 500,
    lineHeight: 1.75,
  },
  "& ul" :{
    textAlign: "start !important",
    fontWeight: 500,
  },
  "& blockquote p": {
    fontFamily: "SpaceGrotesk, sans-serif",
    fontStyle: "italic",
    fontWeight: 600,
    width: "100%",
    textAlign: "start",
    padding: `0px ${theme.spacing(2)}px`,
  },
  "& h3": {
    margin: `${theme.spacing(8)}px 0px 0px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(0)}px 0px 0px`,
    },
  },
  "& h6": {
    margin: `${theme.spacing(4)}px 0px 0px`,
  },
},
}));

const PersonalDataManagementPage = ({data}) => {
  const { mdx: { frontmatter, body } } = data;
  const classes = useStyles();
  return (
    <Layout pageName="cgv">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <Typography 
      className={classes.title} 
      variant="h1">{frontmatter.name}</Typography>
      <Grid
        item
        xs={12} sm={10} md={8} lg={6} xl={5} 
        className={classes.subContainer}
      >
        <MDXRenderer className={classes.containerText}>
          {body}
        </MDXRenderer>
      </Grid>
      </Grid>
    </Layout>
  );
};
export const PersonalDataManagementQuery = graphql`
{
  mdx(frontmatter: {name: {eq: "personal-data-management"}}) {
    body 
    frontmatter {
      title
    }
  }
}
`
export default PersonalDataManagementPage;